import { SIDE_MENU } from './Menu';
import { useState } from 'react';
import { FloatButton, Layout, Menu } from 'antd';
import type { MenuProps } from 'antd';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Registration from './pages/registration';
import Ingredients from './pages/tools/ingredients';
import NetherCalc from './pages/tools/nethercalc';
import About from './pages/about';
import { ViewDasboard } from './pages';

const { Content, Footer, Sider } = Layout;
type MenuItem = Required<MenuProps>['items'][number];
const side_menu: MenuItem[] = SIDE_MENU;

function Home() {
  const navigate = useNavigate()
  const [hide, setHide] = useState(true);
  const [margin, setMargin] = useState('0');

  return (
    <>
      <div style={{ alignItems: 'center' }}>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider hidden={hide} collapsed={false} trigger={null}>
            <div className="demo-logo-vertical" />
            <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={side_menu} onClick={function (v) { navigate(v.key) }} />
          </Sider>
          <Layout>
            <Content style={{ margin: margin }}>
              <Routes>
                <Route path="/" element={<ViewDasboard />} />
                <Route path="/pages/registration" element={<Registration />} />
                <Route path="/pages/tools/ingredients" element={<Ingredients />} />
                <Route path="/pages/tools/nethercalc" element={<NetherCalc />} />
                <Route path="/pages/about" element={<About />} />
              </Routes>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              Cinverse Website ©{new Date().getFullYear()}. Powered By React and Antd Design
            </Footer>
          </Layout>
        </Layout>
        <FloatButton
          type='default'
          icon={hide ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
          onClick={() => {
            setHide(!hide)
            if (hide) {
              setMargin('0 8px')
            } else {
              setMargin('0')
            }
          }}
          style={{
            textAlign: 'left',
            bottom: '10px',
            left: '10px'
          }}
        />
      </div>
    </>


  );
};

export default Home;